import React, { Component } from 'react'
import Footer from './Footer'
import EnquiryBtn from './enquiry-btn';
import TopHeader from './header-section/top-header';
import Navbar from './header-section/navbar';


export default class Layout extends Component {
  render() {
    const { navbarBackgroundColor,navbarBorderBottomColor } = this.props;
    return (
     <>
     <TopHeader/>
     {/* <Navbar backgroundColor={navbarBackgroundColor} borderBottomColor={navbarBorderBottomColor}/> */}
     <Navbar/>
     <EnquiryBtn/>
     {this.props.children}
     <Footer/>
     </>
    )
  }
}
